<template>
	<el-header class="header">
		<img src="../../public/images/logo.svg" height="60px" alt="">
		<ul class="nav max-nav">
			<li><router-link active-class="active" to="/index">首页</router-link></li>
			<li><a target="_blank" href="https://tool.fairy.icu/">工具</a></li>
			<li><router-link active-class="active" to="/record">记录</router-link></li>
			<li><router-link active-class="active" to="/harm">伤害计算</router-link></li>
			<li><router-link active-class="active" to="/story">故事</router-link></li>
			<li><router-link active-class="active" to="/pic">图片</router-link></li>
			<li><router-link active-class="active" to="/userdata">更新面板</router-link></li>
			<li><router-link active-class="active" to="/login">登录</router-link></li>
		</ul>
		<div class="min-nav">
			更多
			<ul class="nav">
				<li><router-link active-class="active" to="/index">首页</router-link></li>
				<li><a  target="_blank" href="https://tool.fairy.icu/">工具</a></li>
				<li><router-link active-class="active" to="/record">记录</router-link></li>
				<li><router-link active-class="active" to="/harm">伤害计算</router-link></li>
				<li><router-link active-class="active" to="/story">故事</router-link></li>
				<li><router-link active-class="active" to="/pic">图片</router-link></li>
				<li><router-link active-class="active" to="/userdata">更新面板</router-link></li>
				<li><router-link active-class="active" to="/login">登录</router-link></li>
			</ul>
		</div>
	</el-header>
</template>

<script>
export default ({
	name: 'HeaderInner',

	components: {

	},
	watch: {

	}
})
</script>

<style lang="less" scoped>
.header {
	width: 100vw;
	background: #fff;
	box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
	display: flex;
	position: fixed;
	z-index: 3;
	left: 0;
	top: 0;
}

.header>img {
	flex: 0;
}

.max-nav {
	display: block;
}

.header .nav {

	width: 100%;
	flex: 1 1 auto;
	display: flex;

	justify-content: flex-end;

	>li {
		padding-left: 0.5rem;
		padding-right: 0.5rem;

		a {
			padding-left: 0.2rem;
			padding-right: 0.2rem;
			display: block;
			height: 100%;
			line-height: 60px;

		}


	}
}

.header .nav>li>.header .nav>li>a:hover {
	border-top: 3px solid #e82323;
	color: #E82323;
	line-height: 55px;
	/* 	text-shadow:
		0 0 10px #e82323,
		0 0 20px #e82323, 
		0 0 40px #e82323; */
}

.header .nav>li .active {
	border-top: 3px solid #e82323;
	color: #E82323;
	line-height: 55px;
}

.header .min-nav {
	display: none;
}

@media screen and (max-width: 768px) {
	.header {
		justify-content: space-between;

		.max-nav {
			display: none;
		}
	}

	.min-nav {
		display: block !important;
		width: 60px;
		height: 60px;
		background: #eee;
		position: relative;
		left: 0;
		top: 0;
		line-height: 60px;
		text-align: center;
		font-size: 18px;
		font-weight: 600;
		

		ul {
			width: 80px;
			position: absolute;
			right: 3rem;
			top: 60px;
			display: none;
			flex-direction: column;

			li {
				display: none;
				width: 120px;
				height: 40px;
				z-index: 5;
				background: #000000;
				color: #fff;

				a {
					line-height: 40px !important;
					text-align: center;
				}

				a:link {
					color: #fff;
				}

				a:active {
					color: #fff;
				}

				a:hover {
					color: #fff;
				}

				a:visited {
					color: #fff;
				}
			}
		}
		

	}
	.min-nav:hover li{
		display: block;
	}

}
</style>